(function () {
  'use strict';

  /* @ngdoc object
   * @name events.people
   * @description
   *
   */
  angular
    .module('events.people', [
      'ui.router',
      'events.people.list'
    ]);
}());
